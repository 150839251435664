import { memoize } from 'lodash/fp';
import * as yup from 'yup';

const configSchema = yup.object({
  REACT_APP_MSAL_CLIENT_ID: yup.string().required(),
  REACT_APP_MSAL_CLIENT_AUTHORITY: yup.string().required(),
  REACT_APP_PORTAL_API_BASE_URL: yup.string().required(),
  REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING: yup.string().required(),
  REACT_APP_MSAL_SCOPES: yup
    .array()
    .transform((value, originalValue) => {
      if (Array.isArray(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(',') : [];
    })
    .of(yup.string().required())
    .required(),
  NODE_ENV: yup.string().required(),
  REACT_APP_SPREEDLY_ENV_KEY: yup.string().required(),
  REACT_APP_MATERIAL_UI_X_PRO_LICENSE_KEY: yup.string().required(),
  REACT_APP_APPLICATION_INSIGHTS_LOGGING_ENABLED: yup.boolean(),
  REACT_APP_LOG_PERMISSION_CHECKING: yup.boolean(),
  REACT_APP_ALLOW_EDIT_OWN_PERMISSIONS: yup.boolean(),
  REACT_APP_TVC_TITLE: yup.string().required(),
  // REACT_APP_FIVE9_TENANT: yup.string().required(),
  REACT_APP_TVC_ALLOW_FLEET_COUPONS: yup.boolean(),
  // REACT_APP_PROMILES_URL: yup.string().required(),
  REACT_APP_FLEET_SUPER_ADMINISTRATOR_GUID: yup.string().required(),
  REACT_APP_ES_FUEL_MAP_NO_FUEL: yup.string().required(),
  REACT_APP_FIVE_NINE_PAUSE_CODE: yup.string().required(),
  REACT_APP_FIVE_NINE_RESUME_CODE: yup.string().required(),
  REACT_APP_BOCA_LINK: yup.string().required(),
  REACT_APP_FLEET_BOCA_LINK: yup.string().required(),
});

// @ts-ignore
const env = window.ENV || process.env;
export type AppConfig = yup.Asserts<typeof configSchema>;

export const parseEnv = memoize(
  (): AppConfig => configSchema.validateSync(env),
);
