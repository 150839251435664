import {
  List,
} from '@mui/material';
import { head } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConfiguration, selectProMilesToken, showOverlay } from '../../../features/application/applicationSlice';
import { useProfile } from '../../../features/auth/hooks/useProfile';
import { FleetUserPermissions } from '../../../features/auth/permissionNames';
import { selectHasTargetedFuelMarketingMessage } from '../../../features/user/userSlice';
import { PortalApiUser } from '../../models/user/PortalApiUser';
import FreezeBadge from '../FreezeBadge';
import { isFleetSuperAdministrator } from '../../utilities/userHelpers';
import { NavItem } from './components/NavItem';
import EsFuelMap from '../../../features/fuel/EsFuelMap';
import { useSelectedProfilePortalUser } from '../../../features/individual-memberships/hooks/useSelectedProfilePortalUser';

interface NavProps {
  onNavListClicked?: () => void;
}

const Nav = (props: NavProps) => {
  const { onNavListClicked } = props;
  const [navItems, setNavItems] = useState<JSX.Element[]>([]);
  const [navSubItems, setNavSubItems] = useState<JSX.Element[]>([]);
  const styles = {
    nav: {
      marginTop: '10px',
    },
  };
  const { userProfile } = useProfile();
  const [userIndividualMembership, setUserIndividualMembership] = useState<PortalApiUser>();
  const userFleet = head(userProfile?.fleets);
  const { selectSelectedProfilePortalUser } = useSelectedProfilePortalUser();
  const isSelectProfileLoading = useSelector(showOverlay);
  const hasTargetedFuelMarketingMessage = useSelector(selectHasTargetedFuelMarketingMessage);
  const proMilesToken = useSelector(selectProMilesToken);
  const proMilesUrl = useSelector(selectConfiguration)?.proMilesUrl;
  const showEsFuelDiscountMapLinkToNonFuelMembers = useSelector(selectConfiguration)?.showEsFuelDiscountMapLinkToNonFuelMembers;

  const getNavigationItems = () => {
    if (!userProfile?.userPermissions) return;

    const linkItems: JSX.Element[] = [];

    // FLEET USER
    if (userFleet) {

      const canViewFleetFuelRoute = userFleet.cachePermissions?.permissions[FleetUserPermissions.canViewFleetFuelRoute];
      const allowFleetFuelRoute = (canViewFleetFuelRoute && userFleet.carrierId) || (canViewFleetFuelRoute && userFleet.showBocaLink);

      linkItems.push(
        <NavItem
          key={`profile-${userFleet.id}`}
          to={`/fleets/${userFleet.id}/profile`}
          linkText={userProfile?.fullName}
        />,
      );

      if (userFleet?.cachePermissions?.permissions[
        FleetUserPermissions.canAccessFleetDashboard
      ]) {
        linkItems.push(
          <NavItem
            key={`dashboard-${userFleet.id}`}
            to={`/fleets/${userFleet.id}/dashboard`}
            linkText="Fleet Dashboard"
          />,
        );
      }

      if (userFleet?.cachePermissions?.permissions[
        FleetUserPermissions.canViewFleetAccount
      ]) {
        linkItems.push(
          <NavItem
            key={`account-${userFleet.id}`}
            to={`/fleets/${userFleet.id}/account`}
            linkText="Fleet Account"
          />,
        );
      }

      if (userFleet?.cachePermissions?.permissions[
        FleetUserPermissions.canViewFleetUsers
      ]) {
        linkItems.push(
          <NavItem
            key={`fleet-users-${userFleet.id}`}
            to={`/fleets/${userFleet.id}/users`}
            linkText="Fleet Users"
          />,
        );
      }

      if (allowFleetFuelRoute) {
        linkItems.push(
          <NavItem
            key={`fuel-route-${userFleet.id}`}
            to={`/fleets/${userFleet.id}/fleet-fuel`}
            linkText="Fleet Fuel"
          />,
        );
      }

      if (userFleet?.carrierId) {
        linkItems.push(
          <EsFuelMap key={`discount-map-${userFleet.id}`} canViewMap />,
        );
      }

      if (userFleet?.cachePermissions?.permissions[
        FleetUserPermissions.canViewFleetLegalRoute
      ]) {
        linkItems.push(
          <NavItem
            key={`legal-route-${userFleet.id}`}
            to={`/fleets/${userFleet.id}/legal`}
            linkText="Fleet Legal"
          />,
        );
      }

      if (proMilesToken && proMilesUrl && isFleetSuperAdministrator(userFleet.permissions)) {
        linkItems.push(
          <NavItem
            key={`promiles-${userFleet.id}`}
            to={{ pathname: `${proMilesUrl}${proMilesToken}` }}
            linkText="Fleet Fuel Tax - ProMiles"
            isExternal
          />,
        );
      }
    }

    // INDIVIDUAL MEMBERSHIP
    if (userIndividualMembership && !userFleet) {
      linkItems.push(
        <NavItem
          key={`individual-membership-account-route-${userProfile.id}`}
          to={`/individual-memberships/${userProfile.id}/account`}
          linkText={<FreezeBadge restrictAccess={userIndividualMembership.restrictAccess}>Account</FreezeBadge>}
        />,
      );

      linkItems.push(
        <NavItem
          key={`individual-membership-dashboard-route-${userProfile.id}`}
          to={`/individual-memberships/${userProfile.id}/dashboard`}
          // linkText={<AttentionBadge showAttentionBadge={hasTargetedFuelMarketingMessage}>Dashboard</AttentionBadge>}
          linkText="Dashboard"
        />,
      );

      if (userProfile?.portalUser?.hasSmartFuelCard || userProfile?.portalUser?.hasCarrierFuelCard || userProfile?.portalUser?.products.some((p) => p.hasFuel)) {
        linkItems.push(
          <NavItem
            key={`individual-membership-fuel-route-${userProfile.id}`}
            to={`/individual-memberships/${userProfile.id}/fuel`}
            linkText={<FreezeBadge restrictAccess={userIndividualMembership.restrictAccess}>Fuel</FreezeBadge>}
          />,
        );
      }

      const showMapLink = Boolean(userProfile?.portalUser?.productHasFuel) || showEsFuelDiscountMapLinkToNonFuelMembers;

      if (userIndividualMembership.showFuelMessaging && showMapLink) {
        linkItems.push(
          <EsFuelMap key={`discount-map-ind-${userProfile.id}`} canViewMap={Boolean(userProfile?.portalUser?.productHasFuel)} />,
        );
      }

      linkItems.push(
        <NavItem
          key={`individual-membership-legal-route-${userProfile.id}`}
          to={`/individual-memberships/${userProfile.id}/legal`}
          linkText="Legal"
        />,
      );

      if (userIndividualMembership.showFuelMessaging && proMilesToken && proMilesUrl) {
        linkItems.push(
          <NavItem
            key={`promiles-${userIndividualMembership.userId}`}
            to={{ pathname: `${proMilesUrl}${proMilesToken}` }}
            linkText="Fuel Tax - ProMiles"
            isExternal
          />,
        );
      }

      linkItems.push(
        <NavItem
          key="contact-us-nav"
          to={`/individual-memberships/${userProfile.id}/contact-us`}
          linkText="Contact Us"
        />,
      );
    }

    // EMPLOYEES
    if (userProfile?.isEmployee) {
      if (userProfile.userPermissions?.canViewAllFleets) {
        linkItems.push(<NavItem key="fleets" to="/fleets" linkText="Fleets" />);
      }

      if (userProfile?.userPermissions?.canViewAllIndividualMemberships) {
        linkItems.push(<NavItem key="individual-memberships" to="/individual-memberships" linkText="Non-Fleet Memberships" />);
      }

      if (userProfile?.userPermissions?.canViewPortalAdministratorRoute) {
        linkItems.push(<NavItem key="admin" to="/admin" linkText="Admin" />);
      }

      linkItems.push(
        <EsFuelMap key={`discount-map-emp-${userProfile.id}`} canViewMap />,
      );

      linkItems.push(<NavItem key="contact-us" to="/contact-us" linkText="Contact Us" />);
    }

    setNavItems(linkItems);
  };

  useEffect(() => {
    if (userProfile?.userPermissions?.canViewAllIndividualMemberships && selectSelectedProfilePortalUser) {
      const linkSubItems: JSX.Element[] = [];
      const fullName = `${selectSelectedProfilePortalUser.firstName} ${selectSelectedProfilePortalUser.lastName}`;
      const base = `/individual-memberships/${selectSelectedProfilePortalUser.userId}`;
      linkSubItems.push(
        <NavItem
          key={`individual-membership-dashboard-route-${selectSelectedProfilePortalUser.userId}`}
          to={`${base}/dashboard`}
          linkText={`${fullName} (${selectSelectedProfilePortalUser.memberId})`}
          isSub
          baseCompare={base}
        />,
      );
      setNavSubItems(linkSubItems);
    } else if (!isSelectProfileLoading) {
      setNavSubItems([]);
    }
  }, [selectSelectedProfilePortalUser, isSelectProfileLoading, userProfile, showEsFuelDiscountMapLinkToNonFuelMembers]);

  useEffect(() => {
    if (userProfile) {
      getNavigationItems();
    }
  }, [userProfile?.userPermissions]);

  useEffect(() => {
    if (userProfile?.portalUser) {
      setUserIndividualMembership(userProfile.portalUser);
    }
  }, [userProfile?.portalUser]);

  useEffect(() => {
    if (userIndividualMembership) {
      getNavigationItems();
    }
  }, [userIndividualMembership, hasTargetedFuelMarketingMessage]);

  useEffect(() => {
    if (proMilesToken && proMilesUrl) {
      getNavigationItems();
    }
  }, [proMilesToken, proMilesUrl]);

  return (
    <>
      <List
        component="nav"
        sx={styles.nav}
        onClick={() => (onNavListClicked ? onNavListClicked() : undefined)}
      >
        <>{navItems.map((ni) => ni)}</>
      </List>
      <List
        component="nav"
        sx={styles.nav}
        onClick={() => (onNavListClicked ? onNavListClicked() : undefined)}
      >
        <>{navSubItems.map((ni) => ni)}</>
      </List>
    </>
  );
};

export default Nav;
